.footr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: calc(var(--unit) * 26);
}

.dialog {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(var(--unit) * 30);
  background: var(--dialog-bg);
  margin: auto;
  width: calc(var(--unit) * 90);
  height: calc(var(--unit) * 90);
  z-index: 200;
  border-radius: var(--border-radius);
}

@keyframes borderAnimation {
  0% { box-shadow: 0 0 0 transparent; }
  50% { box-shadow: 0 0 20px rgba(255, 255, 255, .5); }
  100% { box-shadow: 0 0 0 transparent; }
}

.button_border_animation {
  animation: borderAnimation 1s infinite;
}

.game_counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .spacer {
    height: calc(var(--unit) * 16);
    width: calc(var(--unit) * 16);
  }
  .game_counter_btn {
    position: relative;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--game-dark-green);
    border-radius: var(--border-radius);
    height: calc(var(--unit) * 16);
    width: calc(var(--unit) * 16);
    border: var(--unit-border) solid var(--game-border-color);
    color: var(--game-border-color);
    font-size: 250%;
    overflow: hidden;

    i {
      line-height: 0.8;
    }
    svg {
      width: 80%;
      height: 80%;
    }
  }

  .game_close_btn {
    border-color: var(--game-red);
    color: var(--game-red);
    font-size: 200%;
    animation: borderAnimation 1s infinite;
    .number {
      display: block;
      font-size: 50%;
    }
  }

  .game_done_btn {
    border-color: var(--game-green);
    color: var(--game-green);
    font-size: 200%;
    animation: borderAnimation 1s infinite;

    .number {
      font-size: 50%;
    }
  }

  .game_menu_btn {
    background: #000000;
    color: #e5e5e5;
    border-color: #e5e5e5;
  }

  .game_chart_btn img {
    width: 65%;
    display: block;
  }

  .game_counting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-grow: 1;
    margin: 0 calc(var(--unit) * 2);
    overflow: hidden;

    .game_counting_text {
      color: #ffffff;
      font-size: 200%;
      text-align: center;
      width: 100%;
      height: calc(var(--unit) * 13);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      white-space: pre-wrap;
      &.game_message_text {
        height: calc(var(--unit) * 16);
        line-height: calc(var(--unit) * 7);
        font-size: calc(var(--unit) * 6);
      }
    }

    .game_counting_text_inner {
      font-size: 130%;
    }

    .game_counting_result {
      color: var(--game-red);
      font-size: 25px;
      text-align: center;
      margin-bottom: 5px;
      width: 100%;
    }
  }

  .wrapper {
    width: calc(var(--unit) * 45);
    height: calc(var(--unit) * 3);
    div {
      div:nth-child(1) {
        background-color: var(--game-green) !important;
        span {
          display: none !important;
        }
      }
    }
    div:nth-child(1) {
      height: calc(var(--unit) * 3) !important;
      background-color: transparent !important;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
    }
  }


  .progressbar_white div {
    div:nth-child(1) {
      background-color: #FFFFFF !important;
    }
  }
  .progressbar_warning div {
    div:nth-child(1) {
      background-color: #FF9900 !important;
    }
  }

  .progressbar_danger div {
    div:nth-child(1) {
      background-color: var(--game-red) !important;
    }
  }

  .progressbar_blue div {
    div:nth-child(1) {
      background-color: #4285f4 !important;
    }
  }

  .progressbar_neutral div {
    div:nth-child(1) {
      background-color: #ffffff !important;
    }
  }

  .label {
    display: none;
  }
}
