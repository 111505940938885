.page_content.website_game {
  .loadingPage_counter {
    top: calc(var(--unit) * 14);
    width: calc(var(--unit) * 90);
    .counter_card {
      margin-top: 0;
      background-color: rgba(0,0,0,.7);
    }
  }
}

@keyframes dialog_keyframes {
  0% {
    margin-top: calc(var(--unit) * -5);
    opacity: 0;
  }
  5% {
    margin-top: 0;
    opacity: 1;
  }
  95% {
    margin-top: 0;
    opacity: 1;
  }
  100% {
    margin-top: calc(var(--unit) * 5);
    opacity: 0;
  }
}

.dialog.website {
  top: calc(var(--unit) * 20);
  animation: dialog_keyframes 5s;
  animation-fill-mode: both;
}
