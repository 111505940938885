.select_bet {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: calc(var(--unit) * 12);
  height: calc(var(--unit) * 12);
  max-width: 100%;
  max-height: 100%;
  background: #B7B7B7;
  border-style: solid;
  border-color: #FFFFFF;
  border-width: calc(var(--unit-border) * 4) var(--unit-border);
  font-size: calc(var(--unit) * 5);
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  justify-content: center;
  text-align: center;
  color: #000000;
  z-index: 2;
  transition: all 0.5s;
  @media (min-aspect-ratio: 1/2) {
    border-top-width: calc(var(--unit-border) * 3);
    border-bottom-width: calc(var(--unit-border) * 3);
  }

  &.large-chip {
    width: calc(var(--unit) * 20);
    font-size: calc(var(--unit) * 6);
  }

  @keyframes bounceChip {
    0% { transform: scale(1) }
    50% { transform: scale(0.7)  }
    100% { transform: scale(1)  }
  }
  &.bounce {
    animation: bounceChip 0.5s;
  }

  &.bet_confirm {
    background: #6FA8DC;
    z-index: 1;
    &.bet_winner {
      background: #00FF00;
    }
  }

}
