html,
body {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #434343 !important;
}

.col_center {
  justify-content: center !important;
}

.wheel {
  height: calc(var(--unit) * 90);
  width: calc(var(--unit) * 90);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-image: url("../../../../public/img/roulette_wheel_inner.svg");
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.wheel_container {
  background-repeat: round;
  border-radius: 100%;
  position: relative;
  height: calc(var(--unit) * 90);
  width: calc(var(--unit) * 90);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.outerRim {
  position: absolute;
  left: 0;
  top: 0;
  height: calc(var(--unit) * 90);
  width: calc(var(--unit) * 90);
  background-image: url("../../../../public/img/roulette_wheel_outer.svg");
  overflow: hidden;
  z-index: 101;
}

.ballTrack {
  width: calc(var(--unit) * 32);
  height: calc(var(--unit) * 32);
  position: absolute;
  border-radius: 100%;
  z-index: 2;
}

.ball {
  background-color: #fff;
  width: calc(var(--unit) * 2);
  height: calc(var(--unit) * 2);
  border-radius: 100%;
  position: relative;
  left: calc(var(--unit) * 15);
  top: calc(var(--unit) * 0);
}

.cone {
  position: absolute;
  height: 50%;
  width: 50%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 102;
}

.cone span {
  font-size: 200%;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

@keyframes wheelRotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(359.99deg); }
}

@keyframes ballRotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(-359.99deg); }
}
