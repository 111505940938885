.change_language {
  height: auto !important;
  padding: calc(var(--unit) * 4);
  top: calc(var(--unit) * 32) !important;

  .title {
    margin-bottom: calc(var(--unit) * 4);
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: calc(var(--unit) * 2);
  }

  button {
    display: flex;
    padding: calc(var(--unit) * 4);
    font-size: calc(var(--unit) * 4);
    gap: calc(var(--unit) * 4);
    align-items: center;
    border-radius: var(--border-radius);

    .flag {
      width: calc(var(--unit) * 8);
      height: calc(var(--unit) * 8);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.en .flag { background-image: url('../../../../public/img/flags/en.svg'); }
    &.cn .flag { background-image: url('../../../../public/img/flags/cn.svg'); }
  }
}
