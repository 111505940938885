.stretch-details *:nth-child(2) {
  flex: 1 1 auto;
}

form {
  input {
    border-radius: 24px;
    font-size: 20px;
    height: 48px;
    padding: 0 24px;
    text-align: center;
    border: 1px solid black;
  }
  select {
    border-radius: 24px;
    font-size: 20px;
    height: 48px;
    padding: 0 24px;
    text-align: center;
    border: 1px solid black;
  }
  textarea {
    border-radius: 24px;
    font-size: 20px;
    height: 96px;
    padding: 12px 24px;
    text-align: center;
    border: 1px solid black;
  }

  input:focus-visible,
  select:focus-visible,
  textarea:focus-visible {
    outline: 1px solid black;
  }

  .form_error {
    margin-top: 24px;
    text-align: center;
    color: red;
  }
}
