.center_dialog_box.first_login_message {
  display: flex;
  flex-direction: column;
  background-color: var(--box-bg-gray);
  gap: 20px;
  padding: 30px 20px;
  width: calc(var(--unit) * 96);
  top: calc(var(--unit) * 18 + 16px);
  max-height: none;
  transform: none;

  .dialog_header {
    text-align: center;
    color: var(--accent-color);
    font-size: 20px;
  }
  .dialog_subheader {
    text-align: center;
    font-size: 20px;
  }

  .text_center {
    text-align: center;
  }
  button {
    border-radius: 25px;
    width: 160px;
    height: 50px;
    background: #666;
    color: white;
  }
}
