.subheader {
  position: fixed;
  top: calc(var(--unit) * 18);
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: black;
  height: calc(var(--unit) * 12);
  font-size: calc(var(--unit) * 6);
  color: #F6B26B;
  overflow: hidden;
  z-index: 300;
  .messages {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 400%;
  }
  @keyframes scroll {
    0% { transform: translateX(0); }
    47% { transform: translateX(0); }
    50% { transform: translateX(-100%); }
    72% { transform: translateX(-100%); }
    75% { transform: translateX(-200%); }
    97% { transform: translateX(-200%); }
    100% { transform: translateX(-300%); }
  }
  .red {
    color: #E06666;
  }
  .messages > span {
    flex: 0 0 25%;
    height: calc(var(--unit) * 14);
    text-align: center;
    animation: 12s infinite scroll ease;
    position: relative;
    @keyframes show1 {
      0% { opacity: 0; }
      13% { opacity: 0; }
      16% { opacity: 1; }
      80% { opacity: 1; }
      100% { opacity: 0; }
    }
    @keyframes show2 {
      0% { opacity: 0; }
      29% { opacity: 0; }
      32% { opacity: 1; }
      80% { opacity: 1; }
      100% { opacity: 0; }
    }
    & > span {
      &:nth-child(2) { animation: show1 12s 0s infinite step-end; }
      &:nth-child(3) { animation: show2 12s 0s infinite step-end; }
    }
    &.first_only > span:not(:first-child) {
      opacity: 0 !important;
    }
  }
}

.game_cards {
  display: flex;
  gap: calc(var(--unit) * 3);
  position: absolute;
  top: calc(var(--unit) * 14);
  max-width: 100%;
  left: calc(var(--unit) * 2);
  right: calc(var(--unit) * 2);
  z-index: 300;

  .game_card {
    border-radius: var(--border-radius);
    background: black;
    color: white;
    height: calc(var(--unit) * 18);
    padding: 0 calc(var(--unit) * 6);
    border: var(--unit-border) solid transparent;
    text-align: center;
    width: 50%;
    font-size: calc(var(--unit) * 8);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.green .game_card { border-color: #00FF00; }
  &.red .game_card { border-color: #CC0000; }
}

.view_mode_title {
  position: absolute;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(var(--unit) * 34);
  height: calc(var(--unit) * 14);
  font-size: calc(var(--unit) * 6);
  text-shadow: 1px 1px 2px black;
  background-color: rgba(67, 67, 67, 0.6);
  left: calc(var(--unit) * 2);
  right: calc(var(--unit) * 2);
  color: white;
  border-top: var(--unit-border) solid #666666;
  border-bottom: var(--unit-border) solid #666666;
}
