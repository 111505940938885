.game_picker {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(var(--unit) * 2);

}
.select_game_card {
  width: 100%;
  height:  calc(var(--unit) * 30);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(var(--unit) * 16);
  border-radius: var(--border-radius);
  padding: calc(var(--unit) * 2);

  &.baccarat {
    background-color: var(--color-baccarat);
  }

  &.sicbo {
    background-color: var(--color-sicbo);
  }

  &.roulette {
    background-color: var(--color-roulette);
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset,
    rgb(0 0 0 / 15%) 0px -36px 30px 0px inset,
    rgb(0 0 0 / 10%) 0px -79px 40px 0px inset,
    rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px,
    rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px,
    rgb(0 0 0 / 9%) 0px 32px 16px;
  }

  img {
    max-width: calc(var(--unit) * 28);
    width: 100%;
    display: block;
  }

  .game_name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(var(--unit) * 2);
    font-size: calc(var(--unit) * 6);
    color: #fff;

    .game_text {
      font-style: italic;
    }
  }
}
