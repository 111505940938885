.add_user {
  .label-toggle {
    margin-top: 10px;
    justify-content: center;
    gap: 10px;
  }
  .title {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
  }
}
