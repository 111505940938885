
.transaction_record {
  background-color: var(--app-bg);

  .list_label {
    font-size: 80%;
    margin-top: 10px;
  }
  .bet_history_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    height: 60px;
    z-index: 99999;
    padding: 10px;

    .back_btn {
      width: 40px;
      height: 40px;

      svg {
        color: #fff;
        font-size: 40px;
      }
    }

    .user_name {
      font-size: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }

    .game_info {
      font-size: 35px;
      color: #fff;
    }

    .main_menu {
      .dropdown-toggle::after {
        display: none;
      }

      #dropdown-basic {
        background-color: unset;
        border: unset;
        box-shadow: unset;
        padding: 0 10px;
      }

      .dropdown-menu.show {
        inset: unset !important;
        transform: unset !important;
        right: 0 !important;
        background-color: #ffffff;
      }

      .dropdown-item:active {
        color: #ff9900;
        text-decoration: none;
        background-color: #434343;
      }

      .dropdown-item:focus,
      .dropdown-item:hover {
        color: #000000;
        text-decoration: none;
        background-color: #d9d9d9;
      }

      .dropdown-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
        justify-content: center;
        width: 100%;
        height: 40px;
      }
    }
  }

  .bet_history_main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .history_header {
      min-height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background: #434343;
      border-radius: 10px;

      .history_header_inner {
        width: 100%;
        display: flex;
        align-items: center;

        .header_inner_text {
          width: 25%;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          color: #FF9900;
        }
      }
    }

    .bet_history_body {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: auto;
      overflow-x: hidden;

      .history_body {
        width: 100%;
        min-height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background: #434343;
        border-radius: 10px;

        .history_body_inner {
          width: 100%;
          display: flex;
          align-items: center;

          .body_inner_text {
            width: 25%;
            font-size: 16px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: #fff;
          }
        }
      }
    }
  }
}
