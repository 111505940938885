.sound_vibrate {
    background-color: var(--app-bg);

    .sound_vibrate_inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--box-bg-gray);
        border-radius: 10px;
        padding: 10px 15px;
        gap: 70px;

        .sound_vibrate_info {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            justify-content: space-evenly;
            height: 200px;

            .info_text {
                font-size: 16px;
                display: flex !important;
                flex-direction: column;
                align-items: center;
                text-align: center;
                color: #FFFFFF;
                gap: 10px;

                svg {
                    font-size: 40px;
                }





                .MuiRadio-colorSecondary.Mui-checked,
                .MuiRadio-root {
                    color: #ffffff !important;
                }

                label {
                    margin: 0;
                }
            }

            hr {
                width: 1px;
                height: 80%;
                background-color: #fff;
                opacity: 1;
                margin: 0;
            }


        }
    }



    .sound_vibrate_btn {
        width: 100%;
        max-width: 200px;
        height: 100vh;
        max-height: 70px;
        background: #434343;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #f5f5f5;
        border: unset;
        text-transform: capitalize;
        margin-top: 30%;

    }


}

@media only screen and (min-width: 300px) and (min-height: 430px) {
    .sound_vibrate {
        .sound_vibrate_inner {
            padding: 10px;
            gap: 30px;

            .sound_vibrate_info {
                gap: 10px;


                .info_text {
                    font-size: 15px;
                }


            }
        }

        .sound_vibrate_btn {
            max-width: 120px;
            max-height: 35px;
            font-size: 15px;
        }


    }
}

@media only screen and (min-width: 360px) and (min-height: 485px) {
    .sound_vibrate {
        .sound_vibrate_inner {
            padding: 10px;
            gap: 30px;

            .sound_vibrate_info {
                gap: 10px;


                .info_text {
                    font-size: 16px;
                }


            }
        }



        .sound_vibrate_btn {
            max-width: 140px;
            max-height: 40px;
            font-size: 18px;
        }

    }
}

@media only screen and (min-width: 300px) and (min-height: 550px) {
    .sound_vibrate {
        .sound_vibrate_inner {
            padding: 10px;
            gap: 30px;

            .sound_vibrate_info {
                gap: 10px;


                .info_text {
                    font-size: 16px;
                }


            }
        }



        .sound_vibrate_btn {
            max-width: 140px;
            max-height: 40px;
            font-size: 16px;
        }
    }


}

@media only screen and (min-width: 360px) and (min-height: 620px) {
    .sound_vibrate {
        .sound_vibrate_inner {
            padding: 10px;
            gap: 50px;

            .sound_vibrate_info {


                .info_text {
                    font-size: 16px;
                }


            }
        }



        .sound_vibrate_btn {
            max-width: 150px;
            max-height: 45px;
            font-size: 20px;
        }
    }


}

@media only screen and (min-width: 390px) and (min-height: 660px) {
    .sound_vibrate {
        .sound_vibrate_inner {
            padding: 15px;
            gap: 70px;

            .sound_vibrate_info {


                .info_text {
                    font-size: 18px;
                }


            }
        }


        .sound_vibrate_btn {
            max-width: 160px;
            max-height: 45px;
            font-size: 20px;
        }



    }
}

@media only screen and (min-width: 420px) and (min-height: 700px) {
    .sound_vibrate {
        .sound_vibrate_inner {
            padding: 15px;
            gap: 70px;

            .sound_vibrate_info {


                .info_text {
                    font-size: 20px;
                }


            }
        }



        .sound_vibrate_btn {
            max-width: 170px;
            max-height: 45px;
            font-size: 20px;
        }



    }
}

@media only screen and (min-width: 540px) and (min-height: 930px) {
    .sound_vibrate {
        .sound_vibrate_inner {
            padding: 15px;
            gap: 90px;

            .sound_vibrate_info {


                .info_text {
                    font-size: 22px;
                }


            }
        }


        .sound_vibrate_btn {
            max-width: 180px;
            max-height: 55px;
            font-size: 26px;
        }




    }
}


@media only screen and (min-width: 720px) and (min-height: 1240px) {
    .sound_vibrate {
        .sound_vibrate_inner {
            padding: 20px;
            gap: 100px;

            .sound_vibrate_info {


                .info_text {
                    font-size: 24px;
                }


            }
        }


        .sound_vibrate_btn {
            max-width: 180px;
            max-height: 55px;
            font-size: 28px;


        }
    }
}
