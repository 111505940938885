@import "../../../Styles/stat-page";

.baccarat_statistic {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @include stats;

    .banker { color: var(--baccarat-banker) !important; }
    .player { color: white !important; }
    .tie { color: var(--baccarat-tie) !important; }

    .stat_row.progress_stat .label{
        font-size: calc(var(--unit) * 3);
    }
    .stat_row.progress_stat .progress {
        width: calc(var(--unit) * 50);
    }

    .col-box {
        .col-name {
            padding: calc(var(--unit) * 2) 0;
        }
        .col-stat {
            margin-top: calc(var(--unit) * 0.5);
            padding: calc(var(--unit)) 0;
        }
    }
}
