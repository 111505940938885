.footer_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(var(--unit) * 20);
  flex: 0 0 calc(var(--unit) * 20);
  background-color: #fff;

  .sub_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
    background-color: #666666;

    img {
      width: calc(var(--unit) * 9);
    }

    .sub_menu_text {
      font-size: calc(var(--unit) * 3);
      display: flex;
      align-items: center;
      text-align: center;
      color: #000;
    }

    &.player { background-color: var(--color-baccarat)};
    &.add_player { background-color: var(--color-sicbo)};
    &.message { background-color: var(--color-roulette)};
    &.transaction { background-color: #F4B400; }
  }
}


@media (min-aspect-ratio: 1/2) {
  .footer_menu {
    height: 9vh;
    flex: 0 0 9vh;
  }
}
