.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(var(--unit) * 18);
  z-index: 100;

  &.bg_ {
    background-color: #000;
  }

  &.bg_baccarat_1 {
    background-color: var(--color-baccarat);
  }

  &.bg_roulette_1 {
    background-color: var(--color-roulette);
  }

  &.bg_sicbo_1 {
    background-color: var(--color-sicbo);
  }

  .play60s {
    flex: 1 1 auto;
  }

  .login_btn {
    width: calc(var(--unit) * 20);
    height: calc(var(--unit) * 9);
    margin-left: calc(var(--unit) * 4);
    background: #35654D;
    border: var(--unit-border) solid white;
    color: white;
    border-radius: var(--border-radius);
    padding: 0;
  }

  .language {
    margin-right: calc(var(--unit) * 4);
    width: calc(var(--unit) * 20);
    height: calc(var(--unit) * 9);
    span {
      flex: 0 0 50%;
    }
  }

  & > * {
    position: relative;
    z-index: 102;
  }

  .header-button {
    width: calc(var(--unit) * 18);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.payout {
      text-align: center;
      width: calc(var(--unit) * 18);
      line-height: calc(var(--unit) * 4.5);
      font-size: calc(var(--unit) * 3.5);
      height: calc(var(--unit) * 12);
      border: var(--unit-border) solid white;
      margin-right: calc(var(--unit) * 3);
      padding: 0 calc(var(--unit) * 3);
      border-radius: var(--border-radius);
    }
  }

  .back_btn {
    font-size: calc(var(--unit) * 9);
    color: #fff;
  }

  img {
    width: calc(var(--unit) * 12);
    height: calc(var(--unit) * 12);
  }

  .user_name {
    position: absolute;
    font-size: calc(var(--unit) * 8);
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    z-index: 101;;
  }

  .game_info {
    font-size: calc(var(--unit) * 9);
    color: #fff;
  }

  #dropdown-basic {
    width: calc(var(--unit) * 18);
    height: calc(var(--unit) * 9);
    background-color: unset;
    border: none;
    box-shadow: unset;
    padding: 0;

    i {
      font-size: calc(var(--unit) * 9);
    }

    &:after {
      display: none;
    }
  }

  button.transparent {
    background-color: transparent;
    color: white;
    border: none;
    margin-right: calc(var(--unit) * 4);
    text-transform: uppercase;

    &.login {
      padding: calc(var(--unit) * 2) calc(var(--unit) * 2);
      font-size: 100%;
      border-radius: var(--border-radius);
      font-weight: 700;
    }
  }

  .flag {
    float: right;
    width: calc(var(--unit) * 8);
    height: calc(var(--unit) * 8);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.en { background-image: url('../../../public/img/flags/en.svg'); }
    &.cn { background-image: url('../../../public/img/flags/cn.svg'); }
  }
}

.main_menu {
  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: calc(var(--unit) * 50);
    padding: 0 calc(var(--unit) * 6);
    line-height: calc(var(--unit) * 12);
  }
}
