.balance_widget {
  width: 100%;
  height:  calc(var(--unit) * 20);
  display: flex;
  align-items: center;
  gap: calc(var(--unit) * 3);

  .user_balance {
    flex: 50%;
    height: 100%;
    background: #000000;
    border-radius: var(--border-radius);
    padding: calc(var(--unit) * 0) calc(var(--unit) * 5);
    gap: calc(var(--unit) * 4);
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .balance_icon {
      background: url('../../../public/img/header_balance_10.svg') no-repeat center;
      background-size: contain;
      width: calc(var(--unit) * 10);
      height: calc(var(--unit) * 10);
    }
    .balance_text {
      flex: 1 1 auto;
      font-size: calc(var(--unit) * 6);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: var(--accent-color);

      &:nth-child(2) {
        color: #fff;
      }
    }
  }

  .user_info_inner {
    flex: 50%;
    height: 100%;
    padding: calc(var(--unit) * 1);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-top: var(--unit-border) solid #999999;
    border-bottom: var(--unit-border) solid #999999;

    .user_detail {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .detail_text {
        font-size: calc(var(--unit) * 4);
        display: flex;
        align-items: center;
        text-align: center;
      }
      &.date {
        color: var(--accent-text);
      }
    }
  }

  .reload_icon_icon svg {
    flex-grow: 1;
    font-size: calc(var(--unit) * 15);
    color: #fff;
  }

  .reload_icon_icon.disable svg {
    cursor: default;
    color: #999;
  }
}
