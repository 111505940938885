:root {
  --dialog-bg: rgba(0, 0, 0, 0.7);
  --dialog-text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  --app-bg-1: #434343;
  --app-bg: #666666;
  --game-bg: radial-gradient(#24A062, #1F603F);
  --accent-color: #FF9900;
  --accent-text: #F6B26B;
  --game-border-color: #B0D2B7;
  --box-bg-gray: #434343;
  --button-bg-gray: #666666;
  --border-radius: 10px;
  --unit: 1vw;
  --unit-border: 0.5vw;
  --blue: #4285F4;
  --game-green: #00FF00;
  --game-dark-green: #35654d;
  --game-red: #FF0101;
  --game-black: #000000;
  --roulette-border: #ffffff;
  --color-baccarat: #0f9d58;
  --color-roulette: #4285f4;
  --color-sicbo: #db4437;
  --baccarat-banker: #EA9999;
  --baccarat-tie: #F9CB9C;
  --baccarat-player: #FFFFFF;
}

html, body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

body {
  display: block;
  justify-content: center;
  align-items: flex-start;
  background-color: #434343;
  color: #FFFFFF;
  font-family: 'Inter';
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(var(--unit) * 4);

  .header {
    height: calc(var(--unit) * 18);
    flex: 0 0 calc(var(--unit) * 18);
    position: relative;
    z-index: 1000;
  }

  &.game_container {
    background: var(--game-bg);
    .page_content {
      width: calc(var(--unit) * 100);
      height: calc(100% - var(--unit) * 18);
      padding: 0;
      .main_layout {
        width: calc(var(--unit) * 90);
        height: calc(var(--unit) * 90);
      }
    }
  }
}

.page_content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 10px;
  align-items: center;
  position: relative;
  cursor: pointer;
  //overflow: hidden;
  width: 100%;
  height: calc(100% - var(--unit) * 18);
  overflow: auto;

  &.no_gap {
    padding: 0;
    gap: 0;
  }

  &.full {
    width: 100% !important;
    height: 100% !important;
    padding: 0;
  }

  .main_layout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .main_layout_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  .list {
    .list-header {
      margin: 10px 10px 0;
    }
    .list-body {
      height: 100%;
      padding: 10px;
    }
    button.more {
      height: 40px;
      line-height: 40px;
      border-radius: var(--border-radius);
      border: none;
    }
  }
}

button {
  height: calc(var(--unit) * 9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #434343;
}

.top_dialog_box {
  position: absolute;
  top: calc(var(--unit) * 6);
  width: calc(var(--unit) * 90);
  height: calc(var(--unit) * 18);
  overflow: auto;
  background: var(--dialog-bg);
  text-shadow: var(--dialog-text-shadow);
  border-radius: var(--border-radius);
  z-index: 400;
}
.website_game {
  .top_dialog_box {
    top: calc(var(--unit) * 14);
    width: calc(var(--unit) * 96);
  }
}

.center_dialog_box {
  position: absolute;
  top: calc(var(--unit) * 75);
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  width: calc(var(--unit) * 90);
  max-height: calc(var(--unit) * 100);
  padding: calc(var(--unit) * 6);
  overflow: auto;
  background: var(--dialog-bg);
  text-shadow: var(--dialog-text-shadow);
  border-radius: var(--border-radius);
  z-index: 200;
}

@media (min-aspect-ratio: 1/2) {
  :root {
    --border-radius: 10px;
    --unit: 0.5dvh;
    --unit-border: 0.35dvh;
  }
}
@media (min-aspect-ratio: 4/3) AND (max-aspect-ratio: 3/2) {
  .website_game {
    --unit: 0.43dvh;
    --unit-border: 0.35dvh;
  }
}

@import "./Styles/labels";
@import "./Styles/table";
@import "./Styles/dialog";

#webpack-dev-server-client-overlay {
  display: none;
}
