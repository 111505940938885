#user_table {
  .row2 {
    flex-direction: column;
    align-items: flex-start;
  }
  .table_body_list {
    height: 60px;
  }
}
.filter_row {
  display: flex;
  justify-content: center;
  gap: 10px;
  button {
    height: 30px;
    padding: 0 15px;
    &.selected {
      background-color: var(--box-bg-gray);
      color: white;
      padding-right: 10px;
    }
    i { padding-left: 10px; }
  }
}
