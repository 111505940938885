.pagenotfound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #434343;
    height: 100vh;
    width: 100vw;

    .pagenotfound_image {
        width: 200px;
        height: 200px;
        display: block;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
            display: block;
        }
    }

    .pagenotfound_text {
        font-size: 30px;
        color: #fff;
        text-align: center;
        width: calc(100% - 20%);
        margin: 0 auto 30px;
    }
}