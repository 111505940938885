.transaction_details {
  .username {
    background-color: var(--box-bg-gray);
    padding: 20px;
    color: white;
    border-radius: var(--border-radius);
    text-align: center;
  }

  .detail {
    background-color: var(--box-bg-gray);
    padding: 20px;
    color: white;
    border-radius: var(--border-radius);
    .logo {
      font-size: 24px;
      text-align: center;
      span {
        color: red;
      }
    }
    .data {
      margin-top: 20px;
      display: flex;
      .label {
        flex: 0 0 50%;
        color: var(--accent-color)
      }
    }
    .change_value {
      margin-top: 30px;
      font-size: 24px;
      text-align: center;
    }
  }
}
