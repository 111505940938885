.messages_widget {
  display: flex;
  width: 100%;
  background-color: var(--button-bg-gray);
  border-radius: var(--border-radius);
  padding: calc(var(--unit) * 0) calc(var(--unit) * 5);
  height:  calc(var(--unit) * 20);
  align-items: center;

  .message_icon {
    background: url('../../../public/img/header_message.svg') no-repeat center;
    background-size: contain;
    width: calc(var(--unit) * 10);
    height: calc(var(--unit) * 10);
  }
  .message_details {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    font-size: calc(var(--unit) * 4);
    .message_info {
      display: flex;
      gap: calc(var(--unit) * 4);
      align-items: center;
      .message_sender {
        font-size: calc(var(--unit) * 5);
      }
      .message_time {
        color: var(--accent-text);
      }
    }
  }
}
