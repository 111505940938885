$top: calc(var(--unit) * 50);
$card-height: calc(var(--unit) * 30);
$game-height: calc(var(--unit) * 113);

.all_games {
  position: relative;
  display: flex;
  gap: calc(var(--unit) * 2);
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  .all_games_container {
    position: relative;
    display: flex;
    gap: calc(var(--unit) * 2);
    flex-direction: column;
    padding-top: $top;
    padding-bottom: calc(var(--unit) * 4);
    overflow: auto;
    scroll-behavior: smooth;
  }
}

.game_box_container {
  @keyframes hideLogo {
    0% {
      opacity: 1;
      height: $card-height;
      z-index: 20;
    }
    50% {
      height: $game-height;
      opacity: 1;
      z-index: 20;
    }
    100% {
      height: $game-height;
      opacity: 0;
      z-index: 20;
    }
    100% {
      z-index: -1;
    }
  }
  @keyframes showGame {
    0% {
      opacity: 0;
      height: $card-height;
    }
    50% {
      opacity: 0;
      height: $game-height;
    }
    100% {
      opacity: 1;
      height: $game-height;
    }
  }
  .select_game_card {
    position: absolute;
    left: calc(var(--unit) * 0);
    right: calc(var(--unit) * 0);
    width: auto;
    z-index: 20;
    animation: hideLogo 1.5s;
    animation-fill-mode: forwards;
  }
  .game_box {
    animation: showGame 1.5s;
    animation-fill-mode: both;
  }
  &.baccarat .game_title { background-color: var(--color-baccarat); }
  &.baccarat .game_play_button { background: var(--color-baccarat); }
  &.baccarat .select_game_card,
  &.baccarat .game_box {
    animation-delay: 3s;
  }
  &.sicbo .game_title { background-color: var(--color-sicbo); }
  &.sicbo .game_play_button { background: var(--color-sicbo); }
  &.sicbo .select_game_card,
  &.sicbo .game_box {
    animation-delay: 1.75s;
  }
  &.roulette .game_title { background-color: var(--color-roulette); }
  &.roulette .game_play_button { background: var(--color-roulette); }
  &.roulette .select_game_card,
  &.roulette .game_box {
    animation-delay: 0.5s;
  }
  &.roulette {
    @keyframes reduce {
      0% { margin-bottom: calc($game-height); }
      100% { margin-bottom: 0; }
    }
    margin-bottom: 0;
    animation: reduce 5s;
    animation-timing-function: step-end;
  }
}
.game_box {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--game-bg);
  padding: calc(var(--unit) * 3);
  width: calc(var(--unit) * 96);
  border-radius: var(--border-radius);
  gap: calc(var(--unit) * 3);
  color: white;
}

.scroll_to {
  position: absolute;
  top: 0;

  @keyframes scrollTo {
    0% { top: calc(var(--unit) * 63); }
    25% { top: calc(var(--unit) * 30); }
    50% { top: 0; }
    50% { top: 0; }
  }
  animation: scrollTo 4s;
  animation-timing-function: step-end;
  animation-fill-mode: both;
}


@media (min-aspect-ratio: 4/3) {
  .all_games .all_games_container {
    width: 300%;
    flex-direction: row;
    justify-content: center;
    padding-top: calc(var(--unit) * 41);
    gap: calc(var(--unit) * 5);
  }
  .game_box_container .select_game_card {
    display: none;
  }
  .game_box_container .select_game_card,
  .game_box_container .game_box {
    animation: none !important;
  }
  .game_cards {
    top: calc(var(--unit) * 17) !important;
    left: calc(var(--unit) * -99) !important;
    right: calc(var(--unit) * -99) !important;
    max-width: none !important;
    justify-content: space-between;
    .game_card {
      width: calc(var(--unit) * 96) !important;
    }
  }
  .view_mode_title {
    top: calc(var(--unit) * 17) !important;
    height: calc(var(--unit) * 18) !important;
  }

  @media (max-height: 600px) {
    .all_games .dice_div .defaultFace span {
      padding: 4px !important;
    }
  }
}
