._space3d:not(.rolling) {
  ._3dface { transform: none !important; margin-left: 0 !important; left: 0 !important; top: 0 !important; }
  ._3dbox { transform: none !important; }
  &.one ._3dface--one { z-index: 10; }
  &.two ._3dface--two { z-index: 10; }
  &.three ._3dface--three { z-index: 10; }
  &.four ._3dface--four { z-index: 10; }
  &.five ._3dface--five { z-index: 10; }
  &.six ._3dface--six { z-index: 10; }
}
