.label-toggle {
  display: flex;
  justify-content: flex-end;
  .label {
    padding: 0 15px;
    height: 30px;
    line-height: normal;
    background-color: white;
    border: 1px solid var(--box-bg-gray);
    white-space: nowrap;

    &.selected {
      background-color: var(--box-bg-gray);
      color: white;
      padding-right: 10px;
    }
    i { padding-left: 10px; }
  }
}
