.dialog.payout_info {
  display: flex;
  flex-direction: column;
  padding: calc(var(--unit) * 4) calc(var(--unit) * 6);
  gap: calc(var(--unit) * 4);
  z-index: 420;
  min-height: calc(var(--unit) * 90);
  height: auto;
  .dialog_title {
    font-weight: 700;
    font-size: 120%;
    text-align: center;
    color: var(--accent-color);
  }
  .dialog_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    gap: calc(var(--unit) * 4);
  }

  table {
    width: 80%;
    border: var(--unit-border) solid white;
    thead tr {
      border-bottom: var(--unit-border) solid white;
    }
    tbody tr:not(:last-child) {
      border-bottom: var(--unit-border) solid white;
    }
    th:not(:last-child),
    td:not(:last-child) {
      border-right: var(--unit-border) solid white;
    }
    th,
    td {
      padding: calc(var(--unit) * 1) calc(var(--unit) * 2);
      span {
        display: block;
        font-size: 70%;
        color: #E06666;
      }
    }
  }

  .flag {
    text-align: center;
    width: 80%;
    font-size: 70%;
    color: #E06666;
  }

  button {
    border-radius: var(--border-radius);
    height: calc(var(--unit) * 12);
    padding: 0 calc(var(--unit) * 6);
  }
}
