@import "../../../Styles/stat-page";

.sicbo_statistic {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @include stats;

    .statistic_chips {
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0;
        align-content: center;
        .cell {
            margin: 0 !important;
            width: 10%;
            height: 33.33%;
            font-size: calc(var(--unit) * 3);
            border: none;
            outline: var(--unit-border) solid var(--game-border-color);
            outline-offset: calc(var(--unit-border) * -0.5);
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .chip {
            margin: calc(var(--unit) * 3);
            margin-right: 0;
            width: calc(var(--unit) * 14);
            height: calc(var(--unit) * 14);
            font-size: calc(var(--unit) * 6);
        }
    }

    .col-box {
        flex-basis: calc(100% / 6);
        font-size: calc(var(--unit) * 4);
        .col-img {
            padding: calc(var(--unit) * 1.5) 0;
            img {
                width: calc(var(--unit) * 8);
            }
        }
        .col-stat {
            margin-top: calc(var(--unit) * 0.5);
            padding: calc(var(--unit)) 0;
        }
    }
}
