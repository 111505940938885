.website_game .dialog.baccarat_result {
  top: calc(var(--unit) * 58) !important;
  width: calc(var(--unit) * 90) !important;
}

.dialog.baccarat_result {
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(var(--unit)*68) !important;
  height: calc(var(--unit)*52) !important;

  @keyframes bounce {
    0% { font-size: 200% }
    50% { font-size: 250% }
    100% { font-size: 200% }
  }

  .result_inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--baccarat-banker);
    font-size: 200%;
    animation: bounce 0.5s;

    &.player {
      color: var(--baccarat-player);
    }

    &.tie {
      color: var(--baccarat-tie);
    }
  }
}
