    .page_not_spotted_main {
        width: 100vw;
        height: 100vh;
        display: none;
        z-index: 1000;
        background-color: #fff;
        align-items: center;
        justify-content: center;

        .page_not_spotted {
            display: flex;
            flex-direction: column;
            max-width: 299px;
            max-height: 549px;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            padding: 16px;
            background-color: #fff;

            .page_not_spotted_img {
                width: 90%;
                height: auto;
                display: block;
                margin: 0 auto 25%;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }

            .page_not_spotted_text {
                font-size: 14px;
                font-weight: 500;
                font-family: Roboto, sans-serif;
                display: block;
                line-height: 24px;
                text-align: center;
                margin: 0 auto;
                color: #4d4d4d;

                a {
                    color: #4c84ff !important;
                }
            }
        }
    }

    @media only screen and (max-width: 299px) {
        .page_not_spotted_main {
            display: flex !important;
        }

        .homeroulette,
        .homebaccarat,
        .homesicbo,
        .login {
            display: none !important;
        }
    }

    // @media only screen and (max-height: 549px) {
    //     .page_not_spotted_main {
    //         display: flex !important;
    //     }

    //     .homeroulette,
    //     .homebaccarat,
    //     .homesicbo,
    //     .login {
    //         display: none !important;
    //     }
    // }