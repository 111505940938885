.baccaratbetting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .baccaratbetting_cards {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(var(--unit) * 38);

    .cards_inner:nth-child(2) {
      border-right: unset;
    }

    .cards_inner {
      border-right: var(--unit-border) solid var(--game-border-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      height: 100%;

      .card_text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40%;
        padding: 0 calc(var(--unit) * 5);
        font-size: 150%;
        line-height: 1.1;
        text-align: center;

        .card_labels {
          display: flex;
          flex-direction: column;
        }

        .result_number {
          width: calc(var(--unit) * 10);
          display: inline-block;
          text-align: center;
          font-size: 150%;
          transition: 0.15s ease-in-out;

          &.animation {
            font-size: 200%;
          }
        }

        &.banker {
          color: var(--baccarat-banker);
        }
        &.player {
          color: var(--baccarat-player);
        }
      }
    }
  }


  .baccaratbetting_bets_row2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(var(--unit) * 22);
    border: var(--unit-border) solid var(--game-border-color);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    overflow: hidden;

    .bets2_inner:nth-child(2) {
      border-right: unset;
    }

    .bets2_inner {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      border-right: var(--unit-border) solid var(--game-border-color);

      .bets2_text {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        width: 100%;
        font-size: 120%;
        line-height: 120%;
        gap: calc(var(--unit) * 2);
      }

      .banker_win_text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 90%;
        margin-top: calc(var(--unit) * 1);
      }

      .bets2_text2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #e5e5e5;
      }

      .banker_win_text2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        color: #e5e5e5;
      }
    }
  }

  .baccaratbetting_bets_row3 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(var(--unit) * 30);
    border: var(--unit-border) solid var(--game-border-color);
    border-top: unset;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    overflow: hidden;

    .bets_inner:nth-child(2) {
      border-left: var(--unit-border) solid var(--game-border-color);
      border-right: var(--unit-border) solid var(--game-border-color);
    }

    .bets_inner {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: 100%;

      .bets_text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        white-space: nowrap;
        font-size: 140%;
        line-height: 140%;
        &.odds {
          font-size: 90%;
          margin-top: calc(var(--unit) * 1);
        }
      }
    }
  }
}

.red {
  color: var(--baccarat-banker);
}

.white {
  color: #e5e5e5;
}

.yellow {
  color: var(--baccarat-tie);
}

.all_card {
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  height: 60%;
  width: 100%;
  gap: calc(var(--unit) * 1);

  .ReactFlipCard__container {
    width: auto;
    height: 100%;
  }

  &.player {
    justify-content: flex-end;
    padding-right: calc(var(--unit) * 3);
  }
  &.banker {
    justify-content: flex-start;
    padding-left: calc(var(--unit) * 3);
  }
}
