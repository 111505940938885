.login {
  background-color: var(--game-bg);

  .page_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: calc(var(--unit) * 10);
    padding: calc(var(--unit) * 6);
  }

  .play60s {
    width: 100%;
  }

  .profile_pic {
    margin-top: calc(var(--unit) * -4);
    width: calc(var(--unit) * 30);
    height: calc(var(--unit) * 30);
    display: block;
  }

  .login_div {
    max-width: calc(var(--unit) * 90);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: calc(var(--unit) * 6);
    padding: calc(var(--unit) * 8);
    background: var(--dialog-bg);
    border-radius: var(--border-radius);

    .input_box {
      width: 100%;
      max-width: calc(var(--unit) * 70);
      border-radius: var(--border-radius);
      border: var(--unit-border) solid var(--box-bg-gray);
      padding: calc(var(--unit) * 2);
      color: #000 !important;
      text-align: center;
      font-size: calc(var(--unit) * 6);
      outline: unset;
    }

    .login_btn {
      width: 100%;
      max-width: calc(var(--unit) * 40);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: calc(var(--unit) * 6);
      border-radius: var(--border-radius);
      box-shadow: none;
      background: #db4437;
      color: white;
      text-transform: capitalize;
    }

  }
  .language_btn {
    display: flex;
    gap: calc(var(--unit) * 6);
    align-items: center;
    background: transparent;
    color: #ccc;
    height: auto;
    border: var(--unit-border) solid #ccc;
    border-radius: var(--border-radius);
    padding: calc(var(--unit) * 2) calc(var(--unit) * 4);
    font-size: calc(var(--unit) * 8);
    .dropdown {
      font-size: 50%;
    }
  }
}
