.start-user-info {
  position: relative;
  width: 100%;
  height: calc(var(--unit) * 12);
}
.user_info_widget {
  width: 100%;
  height: calc(var(--unit) * 12);
  align-items: center;
  display: flex;
  font-size: calc(var(--unit) * 6);
  justify-content: center;
  gap: calc(var(--unit) * 5);
  padding: calc(var(--unit) * 0) calc(var(--unit) * 5);
  .user_name_icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: calc(var(--unit) * 10);
    height: calc(var(--unit) * 10);
    &.member { background-image: url('../../../public/img/header_user.svg'); }
    &.parent { background-image: url('../../../public/img/header_parent.svg'); }
  }
  .user_member_name {
    color: var(--accent-text);
  }

  &:first-child {
    animation: showHide 6s infinite;
    animation-fill-mode: both;
  }

  &:nth-child(2) {
    top: 0;
    position: absolute;
    animation: showHide 6s infinite;
    animation-delay: 3s;
    animation-fill-mode: both;
  }

}

@keyframes showHide {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
