.full_table {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  padding-bottom: 10px;

  .table_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 0;
    background-color: var(--box-bg-gray);
    color: var(--accent-color);

    &.sortable {
      background-color: transparent;
      gap: 15px;
      padding: 5px 0;

      .table_header_text {
        padding-top: 5px;
        padding-bottom: 5px;
        border: 1px solid var(--box-bg-gray);
        border-radius: var(--border-radius);
        color: #000000;
        cursor: pointer;

        &.order_asc,
        &.order_desc {
          background-color: var(--box-bg-gray);
          color: #FFFFFF;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &.order_asc:after {
          content: '⬆';
        }
        &.order_desc:after {
          content: '⬇';
        }
      }
    }

  }
  .table_header_text {
    width: 25%;
    padding: 0 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: left;
    color: var(--accent-color);
    &.left {
      justify-content: flex-start;
      padding-left: 10px;
    }
    &:first-child {
      padding-left: 10px;
    }
  }

  .table_body_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 5px;
    padding: 5px 0;
    overflow: auto;
    flex: 1 1 auto;
  }
  .table_body_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .table_body_section {
    width: 100%;
    position: relative;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    &:after {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      bottom: 50%;
      z-index: 0;
      border-bottom: 2px solid var(--accent-color);
    }
  }
  .info_message {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding: 20px;
    font-weight: 500;
    border-radius: 20px;
    margin-top: 20px;
  }
  .section_title {
    background-color: #d9d9d9;
    padding-right: 10px;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: var(--accent-color);
    height: 40px;
    min-height: 40px;
    position: relative;
    z-index: 1;
  }

  .table_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .table_body_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    min-height: 40px;
  }
  .body_list {
    width: 25%;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.left {
      justify-content: flex-start;
      padding-left: 10px;
    }
    &:first-child {
      padding-left: 10px;
    }
  }
  &#user_table {
    .table_header_text,
    .body_list {
      width: 35%;
    }
    .table_header_text:last-child,
    .body_list:last-child {
      width: 30%;
    }
  }

  &.button_rows {
    .table_header {
      background-color: transparent;
    }
    .table_header_text {
      color: black;
    }
    .table_body {
      gap: 10px;
    }
    .table_body_list {
      background-color: var(--button-bg-gray);
      color: white;
      border-radius: var(--border-radius);
      cursor: pointer;
      &:hover {
        background-color: var(--box-bg-gray);
      }
    }
  }
}
