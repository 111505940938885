.play60s {
  position: relative;
  font-size: calc(var(--unit) * 7);
  height: calc(var(--unit) * 12);
  line-height: calc(var(--unit) * 12);
  white-space: nowrap;

  &.large {
    font-size: calc(var(--unit) * 10);
    height: calc(var(--unit) * 20);
    line-height: calc(var(--unit) * 20);
  }

  .first, .second {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  span span {
    color: var(--game-red)
  }

  @keyframes showHide {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  .first {
    animation: showHide 6s infinite;
    animation-fill-mode: both;
  }

  .second {
    animation: showHide 6s infinite;
    animation-delay: 3s;
    animation-fill-mode: both;
  }
}
