.start {

  .start_inner {
    gap: calc(var(--unit) * 5);
    padding: calc(var(--unit) * 5);
    max-width: calc(var(--unit) * 135);

    .game_picker {
      gap: calc(var(--unit) * 5);
    }
  }
}

