@keyframes fadeInCard {
  from {
    transform: scale(1.2) rotate(10deg) translateY(40px);
  }

  to {
    transform: scale(1) rotate(0deg) translateY(0px);
  }
}

.playing_cards {
  width: calc(var(--unit) * 13);
  height: calc(var(--unit) * 18);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: #ffffff;
  border: var(--unit-border) solid #666666;
  border-radius: calc(var(--unit) * 1.5);
  padding: calc(var(--unit) * 1);
  animation: fadeInCard 0.6s;

  .card_icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 25%;
    display: flex;
  }

  .S {
    background-image: url("../../../../public/img/Spades.svg");
    color: #000 !important;
  }
  .H {
    background-image: url("../../../../public/img/Hearts.svg");
    color: #ff0000 !important;
  }
  .C {
    background-image: url("../../../../public/img/Clubs.svg");
    color: #000 !important;
  }
  .D {
    background-image: url("../../../../public/img/Diamonds.svg");
    color: #ff0000 !important;
  }
  .numbers {
    width: 100%;
    height: 75%;
    display: flex;
    font-weight: 500;
    font-size: 200%;
    justify-content: center;
    text-align: center;
    color: #ff0000;
    background-image: unset !important;
  }
}
