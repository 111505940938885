.reset-password {
  background-color: var(--app-bg);
  padding: 10px;

  .reset-password_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--box-bg-gray);
    border-radius: 10px;
    padding: 10px 15px;
    gap: 70px;

    .reset-password_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .password_text {
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #FF9900;
      }

      .info_text {
        font-size: 20px;
        color: #FFFFFF;
      }

      .reset-password_btn {
        width: auto;
        height: 55px;
        padding: 0 20px;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #666666;
        border-radius: 25px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        text-transform: capitalize;
      }
    }
  }

  .reset-password-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
    padding: 0 20px;

    .input_box {
      width: 100%;
      height: 100vh;
      max-height: 70px;
      max-width: 450px;
      border-radius: 50px;
      border: 1px solid #434343;
      padding: 10px;
      color: #000 !important;
      text-align: center;
      font-size: 20px;
      outline: unset;


      &:focus {
        border: 2px solid #ff9900;
      }
    }

    .login_btn {
      width: 100%;
      max-width: 200px;
      height: 100vh;
      max-height: 70px;
      background: #434343;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #f5f5f5;
      border: unset;
      text-transform: capitalize;
    }
  }

  .message {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    border-radius: 35px;
    padding: 0 20px;
    text-align: center;
    color: white;
    font-size: 20px;
    &.success {
      border: 2px solid var(--game-green);
    }
    &.error {
      border: 2px solid var(--game-red);
    }
  }
}
