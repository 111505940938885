button.language {
  background: transparent;
  border: var(--unit-border) solid white;
  border-radius: var(--border-radius);
  color: white;
  padding: 0;
  display: flex;
  overflow: hidden;
  margin-right: calc(var(--unit) * 3);
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: stretch;
  &.narrow {
    span {
      padding: calc(var(--unit) * 1);
    }
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(var(--unit) * 1) calc(var(--unit) * 4);
    border-radius: calc(var(--border-radius) / 2);
  }
  span.selected {
    background: #6FA8DC;
  }
}
