.roulette_inner {
  justify-content: space-between !important;
}
.game_bet_two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 18%;

  .bet_two_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36%;
    height: 100%;
    border: var(--unit-border) solid var(--game-border-color);
    font-size: 150%;
    text-align: center;
    position: relative;
    border-radius: var(--border-radius);
  }

  .bet_two_box1 {
    width: calc(var(--unit) * 18);
    margin: 0 calc(var(--unit) * 3.5);
  }
}

.website_game {
  .roulettewheel_div {
    top: calc(var(--unit) * 19);
    .outerRim {
      transform-origin: bottom !important;
      transform: scale(1) !important;
    }
  }
}

.game_bet_three {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 18%;
  border: var(--unit-border) solid var(--game-border-color);
  border-radius: var(--border-radius);
  overflow: hidden;

  .bet_three_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    font-size: 130%;
    line-height: 130%;
    text-align: center;
    position: relative;
    &:not(:last-child) {
      border-right: var(--unit-border) solid var(--game-border-color);
    }

    img {
      width: 80%;
      height: 80%;
      display: block;
    }
  }
}

.game_bet_main .red {
  color: #ff0101;
}

.game_bet_main .black {
  color: #000;
}

.selected_bet {
  background: var(--box-bg-gray) !important;
}
