.game_header {
  display: flex;
  gap: calc(var(--unit) * 3);
  white-space: nowrap;
  .game_play_button {
    position: relative;
    width: 33.33%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--unit) * 14);
    padding: 0 calc(var(--unit) * 4);
    text-align: center;
    border-radius: var(--border-radius);
    font-size: calc(var(--unit) * 5);
    border: var(--unit-border) solid white;
    z-index: 1000;
    @keyframes highlight {
      0% { box-shadow: none; }
      50% { box-shadow: 0 0 calc(var(--unit) * 4) rgba(255,255,255,.8); }
      100% { box-shadow: none; }
    }
    animation: 1s highlight infinite;
  }
  .game_title {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    background: black;
    color: white;
    height: calc(var(--unit) * 14);
    flex: 1 1 auto;
    font-size: calc(var(--unit) * 6);
  }
}

