.trial_home {
  background-color: var(--app-bg);
  .page_content {
    height: 100%;
    gap: 40px;
  }
  button.secondary {
    padding: 8px 24px;
    border-radius: var(--border-radius);
  }
}
