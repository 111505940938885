@import "../../../Styles/stat-page";

.roulette_statistic {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @include stats;

    .progress {
        .progressbar_red {
            background-color: var(--game-red) !important;
            color: #FFF !important;
        }
        .progressbar_low {
            background-color: #0F9D58 !important;
            color: white !important;
        }
        .progressbar_high {
            background-color: #F4B400 !important;
            color: white !important;
        }
        .progressbar_even {
            background-color: #4285F4 !important;
            color: white !important;
        }
        .progressbar_odd {
            background-color: #DB4437 !important;
            color: white !important;
        }
    }

    .stat_row {
        &.hot_cool {
            display: flex;
            padding: 0;
        }
    }

    .hot_cool_side {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 50%;
        background-color: #DB4437;
        color: #FFFFFF;

        .title {
            width: 100%;
            text-align: center;
            line-height: calc(var(--unit) * 6);
            font-size: calc(var(--unit) * 4);
        }

        .fields {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            gap: calc(var(--unit) * 1);
            padding: 0 calc(var(--unit) * 2);
        }
        &.cool_div {
            background-color: #4285F4;
            border-left: var(--unit-border) solid var(--game-border-color);
        }
    }

    .chip {
        width: calc(var(--unit) * 8);
        height: calc(var(--unit) * 8);
        font-size: calc(var(--unit) * 4);
        &.green {
            background-color: var(--game-dark-green);
        }
    }
}
