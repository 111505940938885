.game_bet_one {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54%;
  border: var(--unit-border) solid var(--game-border-color);
  overflow: hidden;
  border-radius: var(--border-radius);

  .game_bet_main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% / 3);

    &:not(:last-child) {
      border-bottom: var(--unit-border) solid var(--game-border-color);
    }

    .game_bet_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% / 6);
      height: 100%;
      font-size: 150%;
      text-align: center;
      position: relative;

      &:not(:last-child) {
        border-right: var(--unit-border) solid var(--game-border-color);
      }

      .game_bet_box_border {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        color: #FFFFFF;
        text-align: center;
        width: calc(var(--unit) * 10);
        height: calc(var(--unit) * 10);
      }
    }

    .red {
      .game_bet_box_border {
        background-color: #FF0101;
      }
    }

    .black {
      .game_bet_box_border {
        background-color: #000000;
      }
    }
  }
}
