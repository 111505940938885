.loadingPage_counter {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  font-size: calc(var(--unit) * 6);
  padding: calc(var(--unit) * 6);

  .message {
    text-align: center;
  }

  .counter {
    text-align: center;
    span.number {
      font-size: calc(var(--unit) * 12);
      line-height: 1;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
}
