.dialog_message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: calc(var(--unit) * 6);
  .title {
    font-size: 150%;
    text-align: center;
    color: var(--accent-color);
  }
  .message {
    text-align: center;
    font-size: 125%;
  }
  .action {
    display: flex;
    justify-items: center;
  }
  button {
    padding: calc(var(--unit) * 2) calc(var(--unit) * 8);
    border-radius: var(--border-radius);
    background-color: var(--blue);
    border: none;
    font-size: 125%;
    color: #ffffff;
  }
}
