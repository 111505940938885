.row_details_inner {
  .inline_editor {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    .inline_editor_actions {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }
}
