.reset-pass {
  text-align: center;
  .init {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
  }

  .login-details {
    background-color: var(--box-bg-gray);
    padding: 20px;
    color: white;
    border-radius: var(--border-radius);
    .title {
      font-size: 24px;
    }
    .data {
      margin-top: 20px;
      display: flex;
      text-align: left;
      .label {
        flex: 0 0 50%;
        color: var(--accent-color);
      }
    }
  }
}
