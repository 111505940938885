.messages_body {
  .message_list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .message {
      background-color: var(--button-bg-gray);
      border-radius: var(--border-radius);
      color: #FFFFFF;
      padding: 10px 20px;

      &.operator {
        background-color: black;
      }
      &:hover {
        background-color: var(--box-bg-gray);
      }

      &.received {
        margin-left: 40px;
      }
      &.sent {
        margin-right: 40px;
        background-color: #555;
      }

      .message_meta {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .sender {
          font-weight: 700;
        }
      }
    }
  }
  .empty_message {
    text-align: center;
  }

  .send_box {
    margin-bottom: 16px;
    padding: 0 16px 16px;
    border-bottom: 1px solid var(--box-bg-gray);
    form {
      position: relative;
      height: 96px;
    }
    textarea {
      width: 100%;
      padding-right: 120px;
      text-align: left;
    }
    button {
      position: absolute;
      right: 16px;
      top: 16px;
      bottom: 16px;
      padding: 0 16px;
      background-color: var(--button-bg-gray);
      color: white;
      border: none;
      border-radius: var(--border-radius);

      &:hover {
        background-color: var(--box-bg-gray);
      }
    }
  }
}
