.dialog.trial_result {
  display: flex;
  flex-direction: column;
  padding: calc(var(--unit) * 4) calc(var(--unit) * 6);
  gap: calc(var(--unit) * 4);
  z-index: 420;
  .en {
    opacity: 0.6;
  }
  .dialog_title {
    font-weight: 700;
    font-size: 120%;
    text-align: center;
    color: var(--accent-color);
  }
  .dialog_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    gap: calc(var(--unit) * 4);
  }
  ol {
    margin: 0;
    li:not(:first-child) {
      margin-top: calc(var(--unit) * 4);
    }
  }
  .result_message {
    font-size: calc(var(--unit) * 4);
    &.large {
      font-size: calc(var(--unit) * 6);
      font-weight: 500;
    }
    &.win {
      color: var(--game-green);
    }
    &.left {
      width: 100%;
    }
    .balance {
      font-weight: 500;
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    gap: calc(var(--unit) * 6);
  }
  button {
    //margin-top: calc(var(--unit) * 4);
    padding: calc(var(--unit) * 2) calc(var(--unit) * 4);
    border-radius: var(--border-radius);
    border: var(--unit-border) solid white;
    background: #D9D9D9;
    color: #000000;
    &.to_login {
      background-color: black;
      color: white;
    }
    &.to_play {
      background-color: var(--bs-green);
      color: white;
    }
  }
  .login_message {
    margin-top: calc(var(--unit) * 2);
    text-align: center;
    //font-size: calc(var(--unit) * 3);
    //line-height: calc(var(--unit) * 4);
  }
}
