.pages_widget {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: calc(var(--unit) * 5);
  .nav_button {
    display: flex;
    width: 100%;
    background-color: var(--button-bg-gray);
    border-radius: var(--border-radius);
    padding: calc(var(--unit) * 0) calc(var(--unit) * 5);
    height: calc(var(--unit) * 20);
    font-size: calc(var(--unit) * 6);
    align-items: center;
    border: none;
    color: white;
    .icon {
      width: calc(var(--unit) * 10);
      height: calc(var(--unit) * 10);
      background: center no-repeat;
      background-size: 100% 100%;
    }
    & > *:not(.icon) { flex: 0 0 calc((100% - var(--unit) * 10) / 2); }

    &.player { background-color: var(--color-baccarat)};
    &.add_player { background-color: var(--color-sicbo)};
    &.message { background-color: var(--color-roulette)};
    &.transaction { background-color: #F4B400; }

    &.player .icon { background-image: url('../../../public/img/page_player.svg')};
    &.add_player .icon { background-image: url('../../../public/img/page_add_player.svg')};
    &.message .icon { background-image: url('../../../public/img/page_message.svg')};
    &.transaction .icon { background-image: url('../../../public/img/page_transaction.svg')};
  }
}
