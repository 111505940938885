.sicbobetting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  .sicbo_row_one {
    width: 100%;
    height: 26%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: var(--unit-border) solid var(--game-border-color);
    border-radius: var(--border-radius);
    overflow: hidden;

    .row_one_inner:nth-child(2) {
      border-left: var(--unit-border) solid var(--game-border-color);
      border-right: var(--unit-border) solid var(--game-border-color);
      flex-direction: row !important;
    }

    .row_one_inner {
      position: relative;
      width: calc(100% / 3);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 150%;

      img {
        height: 70%;
      }

      .ch {
        line-height: 1;
        font-size: calc(var(--unit) * 10);
      }
      .f {
        line-height: 1;
        margin-top: calc(var(--unit) * 2);
        font-size: calc(var(--unit) * 5);
      }
    }
  }

  .sicbo_row_second {
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--unit-border) solid var(--game-border-color);
    border-radius: var(--border-radius);
    overflow: hidden;
    width: 100%;
    height: 18%;

    .row_second_inner:nth-child(6) {
      border-right: unset;
    }

    .row_second_inner {
      position: relative;
      width: calc(100% / 6);
      height: 100%;
      display: flex;
      flex-direction: column;
      font-size: 150%;
      padding: calc(var(--unit) * 1);
      justify-content: center;
      border-right: var(--unit-border) solid var(--game-border-color);

      img {
        height: calc(var(--unit) * 11);
      }

      .second_inner:nth-child(2) {
        justify-content: space-between;
      }

      .second_inner {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }

  .sicbo_row_third {
    display: flex;
    flex-direction: column;
    border: var(--unit-border) solid var(--game-border-color);
    border-radius: var(--border-radius);
    overflow: hidden;
    width: 100%;
    height: 26%;

    .row_third_inner:nth-child(2) {
      border-bottom: unset;
    }

    .row_third_inner {
      display: flex;
      align-items: center;
      border-bottom: var(--unit-border) solid var(--game-border-color);
      height: 50%;

      .sicbo_bet:nth-child(7) {
        border-right: unset;
      }

      .sicbo_bet {
        position: relative;
        width: calc(100%/7);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 150%;
        line-height: 1;
        color: #ffffff;
        border-right: var(--unit-border) solid var(--game-border-color);
      }
    }
  }

  .sicbo_row_fourth {
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--unit-border) solid var(--game-border-color);
    border-radius: var(--border-radius);
    overflow: hidden;
    width: 100%;
    height: 18%;

    .row_fourth_inner:nth-child(6) {
      border-right: unset;
    }

    .row_fourth_inner {
      position: relative;
      width: calc(100% / 6);
      height: 100%;
      display: flex;
      padding: calc(var(--unit) * 1);
      font-size: 150%;
      justify-content: center;
      align-items: center;
      border-right: var(--unit-border) solid var(--game-border-color);

      .fourth_inner:nth-child(2) {
        justify-content: space-between;
      }

      img {
        height: 60%;
      }
    }
  }
}

.selected-bet {
  background: var(--box-bg-gray) !important;
}

