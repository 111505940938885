.game_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(var(--unit) * 90);
  height: calc(var(--unit) * 30);
  gap: calc(var(--unit) * 1.5);
  position: relative;

  .game_details_main {
    display: flex;
    width: 30%;
    height: calc(var(--unit) * 18);
    border-radius: var(--border-radius);

    .game_details_inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: var(--border-radius);
      align-items: center;
      justify-content: space-between;

      .game_details_icon {
        background: no-repeat center;
        background-size: contain;
        height: calc(var(--unit) * 9);
        width: calc(var(--unit) * 9);
        &.balance { background-image: url("../../../public/img/header_balance.svg"); }
        &.bets { background-image: url("../../../public/img/header_bets.svg"); }
        &.time { background-image: url("../../../public/img/header_time.svg"); }
      }
      .game_details_text {
        line-height: 1;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: var(--accent-color);
        width: 100%;
      }

      .game_details_value {
        font-size: calc(var(--unit) * 6);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        width: 100%;
      }
    }
  }
}
